.canais-oficiais-msg {
    width: 90%;
    margin-left: 10%;
    margin-bottom: 15px;
    font-weight: 600;
    font-size: 22px;
    line-height: 35px;
    color: #014D49;
}

.verticalListItem {
    width: 90%;
    margin-left: 10%;
    display: block;
    overflow: auto;
}

.verticalItemImage {
    min-height: 80px;
    width: 50px;
    float: left;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.img-canais {
    height: 40px;
    width: 40px;
}

.verticalItemText {
    width: calc(100% - 50px);
    float: left;
    display: block;
    line-height: 40px;
}

.verticalItemText .canais-label {
    width: 100%;
    margin-bottom: .5px;
    font-weight: 600;
    font-size: 22px;
    line-height: 35px;
    color: #00995C;
}

.verticalItemText .canais-text {
    width: 100%;
    font-weight: 400;
    font-size: 22px;
    line-height: 35px;
    color: #014D49;
}

@media (width <=768px) {
    .canais-oficiais-msg {
        width: 97%;
        margin-left: 3%;
        margin-top: 15px;
        margin-bottom: 5px;
        font-size: 18px;
        line-height: 30px;
    }

    .verticalListItem {
        width: 97%;
        margin-left: 3%;
    }
    .verticalItemImage {
        min-height: 60px;
    }

    .verticalItemText .canais-label {
        font-size: 18px;
        line-height: 30px;
    }
    
    .verticalItemText .canais-text {
        font-size: 18px;
        line-height: 30px;
    }

}