.painel-camera-detect {
    width: 100%;
    margin-top: 50px;
}

.painel-camera-detect .verticalListItem {
    width: 90%;
    margin-left: 10%;
    margin-bottom: 15px;
    display: block;
    overflow: auto;
}

 .verticalListItem:hover{
    cursor: pointer;
}

.painel-camera-detect .verticalListItem .verticalItemImageLef {
    width: 40px;
    margin-right: 5px;
    float: left;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.painel-camera-detect .verticalListItem .verticalItemText {
    width: calc(100% - 95px);
    float: left;
    display: block;
    line-height: 35px;
    font-size: 15px;
}
.painel-camera-detect .verticalListItem .verticalItemImageRig {
    width: 35px;
    float: right;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.img-camera-svg {
    height: 35px;
    width: 35px;
}

.img-avancar-svg{
    height: 25px;
    width: 25px;  
}
.img-barras-verticais-svg{
    height: 35px;
    width: 35px;   
}


.verticalItemText .camera-detect-text {
    width: 100%;
    margin-bottom: .5px;
    font-weight: 500;
    font-size: 20px;
    line-height: 35px;
}

.text-abort-scanner{
    margin-left: 10%;
    margin-bottom: 15px;
    font-size: 18px;
}

@media (width <=768px) {

    .painel-camera-detect .verticalListItem {
        width: 97%;
        margin-left: 3%;
    }

    .verticalItemText .camera-detect-text {
        font-size: 16px;
    }
    
}