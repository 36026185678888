body {
    font-family: 'Unimed Sans - 2020';
    font-style: normal;
    color: #014D49;
}

.painel-topo {
    min-height: 197px;
    background: #F7F7F7;
}

.linha-topo {
    min-height: 197px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.img-logo-unimed {
    width: 277px;
    height: 102px;
    margin-left: 10%;
}

.img-validar-boleto {
    width: 495px;
    height: 75px;
    margin-left: 10%;
}


.painel-2 {
    margin-top: 20px;
    margin-bottom: 55px;
}

.painel2a {
    min-height: 450px;
}

.img-voltar {
    width: 50px;
    height: 50px;
    margin-left: 10%;
    margin-bottom: 10px;
}

.img-voltar:hover {
    cursor: pointer;
    width: 60px;
    height: 60px;
}

.img-barras-verticais {
    height: 54px;
    width: 73px;
    font-size: 23px;
    margin-left: 10%;
    margin-bottom: 15px;
}

.label-codigo-boleto {
    width: 90%;
    margin-left: 10%;
    margin-bottom: 0px !important;
    font-weight: 400;
    font-size: 23px;
    line-height: 30px;
}

.campo-pesquisa {
    width: 90%;
    margin-left: 10%;
    padding-top: 10px;
    padding-bottom: 20px;

}

.botao-validar {
    width: 90%;
    margin-left: 10%;
    margin-top: 20px;
}

.painel2b {
    min-height: 450px;
}


.alert-result {
    width: 90%;
    margin-left: 10%;
    font-size: 22px;
    line-height: 28px;
    color: #B2CE4D;
}

.alert-error {
    width: 90%;
    margin-left: 10%;
    font-size: 22px;
    line-height: 28px;
    color: #EF7925;
}

#footer {
    background: #00995C;
    height: 70px;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

#footer span {
    font-weight: 350;
    font-size: 18px;
    color: #FFFFFF;
}

.resultado-verde{
    color: #00995C !important;
}

.resultado-laranja{
    color: #EF7925 !important;
}

.resultado-vermelho{
    color: #EB3223 !important;
}

@media (width <=768px) {
    .painel-topo {
        padding-top: 10px;
        min-height: 100px;
    }

    .linha-topo {
        margin-top: 5px;
        min-height: 60px;
        text-align: center;
    }

    .img-logo-unimed {
        width: 40%;
        height: auto;
        margin-left: 3%;
    }

    .img-validar-boleto {
        width: 70%;
        height: auto;
        margin-left: 3%;
    }

    .img-voltar {
        margin-left: 3%;
            margin-bottom: 2px;
    }

    .painel-2 {
        margin-top: 5px;
        margin-bottom: 50px;
    }

    .painel2a {
        min-height: 250px;
    }

    .painel2b {
        min-height: 480px;
    }

    .label-codigo-boleto {
        width: 94%;
        margin-top: 10px;
        margin-left: 3%;
        font-size: 18px;
        line-height: 15px;
    }

    .campo-pesquisa {
        width: 94%;
        margin-left: 3%;
    }

    .botao-validar {
        width: 94%;
        margin-left: 3%;
    }

    .alert-result {
        width: 94%;
        margin-left: 3%;
        margin-left: 3%;
        font-size: 18px;
        margin-bottom: 10px;
    }
    
    .alert-error {
        width: 90%;
        margin-left: 10%;
        margin-bottom: 10px;
        font-size: 22px;
        line-height: 28px;
        color: #EF7925;
    }
    


    #footer {
        height: 80px;
        text-align: center;
        justify-content: center;
        padding-top: 7px;
        align-items:flex-start;
       
    }

    #footer span {
        font-size: 15px;
    }
}