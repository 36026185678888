.label-link-help-codigo-boleto {
    margin-left: 10%;
    font-weight: 400;
    font-size: 24px;
    line-height: 31px;
    text-align: left;
    color: #00995C;
}

.label-link-help-codigo-boleto:hover {
    text-decoration: underline;
    cursor: pointer;
}

.image-link-help-codigo-boleto {
    width: 25px;
    margin-left: 5%;
    filter: invert(15%) sepia(56%) saturate(2731%) hue-rotate(157deg) brightness(99%) contrast(99%);
}

.dialog {
    border-radius: 5px;
    border-style: solid;
    border-color: #014D49;
    box-shadow: 7px 7px 7px -7px #014d49;
    position: static;
    width: 90%;
    max-width: 650px;
    top: 10px;
    z-index: 99;
}

.image {
    width: 100%;
}

@media (width <=768px) {
    .label-link-help-codigo-boleto {
        width: 94%;
        margin-left: 3%;
        text-align: center;
    }
}