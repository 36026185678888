.campo-botao label {
    display: block;
    font-size: 16px;
}

.campo-botao button {
    width: 100%;
    background-color: #00995c;
    border-radius: 8px;
    font-family: 'Unimed Sans - 2020';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 31px;
    padding: 10px;
    border: none;
    cursor: pointer;
    color: #fff;
}

.campo-botao button:hover {
    color: #cde254;
    box-shadow: 2px 2px 5px rgba(239, 121, 37, 0.8);
}

.campo-botao button[disabled]:hover {
    opacity: .6;
    cursor: not-allowed;
}

[disabled] {
    opacity: .6;
    cursor: not-allowed;
}