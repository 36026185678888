
.aviso-seguranca-titulo{
    width: 85%;
    margin-left: 10%;
    margin-right: 5%;
    font-weight: 700;
    font-size: 23px;
    line-height: 30px;
    color: #014D49;  
}

.aviso-seguranca {
    width: 85%;
    margin-left: 10%;
    margin-right: 5%;
    font-weight: 300;
    font-size: 23px;
    line-height: 30px;
    color: #014D49;
}


@media (width <=768px) {
    .aviso-seguranca-titulo{
        width: 94%;
        margin-top: 10px;
        margin-bottom: 5px;
        margin-left: 3%;
        margin-right: 3%;
        font-size: 18px;
        line-height: 25px;
    }

    .aviso-seguranca {
        width: 94%;
        margin-left: 3%;
        margin-right: 3%;
        font-size: 18px;
    }
}

