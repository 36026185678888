

.campo-texto label{
    display: block;
    font-size: 16px;
}

.ctbox{
    width: 100%;
    border-radius: 5px;
    border-style: solid;
    border-color: #014D49;
    box-sizing: border-box;
    border-width: 3px;
}

.ctbox input{
    width: calc(100% - 40px);
    font-weight: 400;
    font-size: 17px;
    line-height: 22px;
    padding: 10px;
    border: none;
    &:focus-visible{
        outline: none;
      }
}

.ctbox img-past-input{
    height: 37px;
    width: 37px;
}

.img-past-input:hover{
    cursor: pointer;
    height: 38px;
    width: 38px;
}

[disabled] {
    opacity: .6;
    cursor: not-allowed;
  }
 




