#wrapper {
  margin: 0 auto;
  width: 95%;
  padding: 2px;
  display: inline;
}

#sidebar-wrapper {
  position: relative;
  float: left;
  height: 100vh !important;
  width: 15%;
  min-width: 100px;
  max-width: 20%;
  z-index: 50;
  display: flex;
  flex-direction: column;
  z-index: 100;
  background-color: #CCC;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
}

#page-content-wrapper {
  position: relative;
  width: 85%;
  min-width: 80%;
  max-width: 90%;
  float: left;
}

#navbar-wrapper {
  top: 0;
  width: 100%;
  min-height: 60px;
  display: flex;
  flex-direction: column;
}


@font-face {
  font-family: 'Unimed Sans - 2020'; 
  src: local('Unimed Sans - 2020'),
    url("./fonts/UnimedSans2020-Rg.otf") format("opentype");
}