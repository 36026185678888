
.loader {
    width: 55px;
    height: 55px;
    margin: 0 10px;
    border-color: #B2CE4D; 
    aspect-ratio: 1;
    border-radius: 10%;
    animation: l6 1s infinite linear 
  } 
 
  @keyframes l6 {
      0%  {box-shadow: 65px 0 #0ff50f, -65px 0 #00995C; background: #00995C}
      50% {box-shadow: 65px 0 #00995C, -65px 0 #0ff50f; background: #00995C}
      66% {box-shadow: 65px 0 #00995C, -65px 0 #00995C; background: #0ff50f}
      100%{box-shadow: 65px 0 #0ff50f, -65px 0 #00995C; background: #00995C}
  }  

